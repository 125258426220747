;(function(){
'use strict';

var _item = require('./item.vue');

var _item2 = _interopRequireDefault(_item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = {
  template: '#items',
  props: ['items'],
  methods: {
    onInput: function onInput(event) {
      this.$emit('input', event.target.value);
    }
  },
  components: {
    Item: _item2.default
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',_vm._l((_vm.items),function(item){return _c('item',{key:item.text,attrs:{"item":item}})}))}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-31307b45", __vue__options__)
  } else {
    hotAPI.reload("data-v-31307b45", __vue__options__)
  }
})()}