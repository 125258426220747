;(function(){
'use strict';

module.exports = {
  props: ['item']
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:{ 'removed': _vm.item.checked}},[_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.checked),expression:"item.checked"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.item.checked)?_vm._i(_vm.item.checked,null)>-1:(_vm.item.checked)},on:{"change":function($event){var $$a=_vm.item.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.item.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.item.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.$set(_vm.item, "checked", $$c)}}}}),_vm._v("\n      "+_vm._s(_vm.item.text)+"\n    ")])])])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-3b46599e"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-3b46599e", __vue__options__)
  } else {
    hotAPI.reload("data-v-3b46599e", __vue__options__)
  }
})()}