;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _item = require('./shopping_list/item.vue');

var _item2 = _interopRequireDefault(_item);

var _add_item = require('./shopping_list/add_item.vue');

var _add_item2 = _interopRequireDefault(_add_item);

var _items = require('./shopping_list/items.vue');

var _items2 = _interopRequireDefault(_items);

var _change_title = require('./shopping_list/change_title.vue');

var _change_title2 = _interopRequireDefault(_change_title);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _data = {
  items: [{ text: 'Bananas', checked: true }, { text: 'Apples', checked: false }],
  title: 'My Shopping List'
};

exports.default = {
  data: function data() {
    return _data;
  },
  components: { AddItem: _add_item2.default, ChangeTitle: _change_title2.default, Item: _item2.default, Items: _items2.default }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"shopping-list"}},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('add-item',{attrs:{"items":_vm.items}}),_vm._v(" "),_c('items',{attrs:{"items":_vm.items}}),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('hr'),_vm._v(" "),_c('change-title',{model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2fc18bd4", __vue__options__)
  } else {
    hotAPI.reload("data-v-2fc18bd4", __vue__options__)
  }
})()}