;(function(){
'use strict';

module.exports = {
  template: '#change-title',
  props: ['value'],
  methods: {
    onInput: function onInput(event) {
      this.$emit('input', event.target.value);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('em',[_vm._v("Change the title of your shopping list here")]),_vm._v(" "),_c('input',{domProps:{"value":_vm.value},on:{"input":_vm.onInput}})])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-bfbc34d8", __vue__options__)
  } else {
    hotAPI.reload("data-v-bfbc34d8", __vue__options__)
  }
})()}