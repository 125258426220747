;(function(){
"use strict";

module.exports = {
  props: ["items"],
  data: function data() {
    return {
      "newItem": ""
    };
  },
  methods: {
    addItem: function addItem() {
      var text;

      text = this.newItem.trim();
      if (text) {
        this.items.push({
          text: text,
          checked: false
        });
        this.newItem = '';
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newItem),expression:"newItem"}],staticClass:"form-control",attrs:{"placeholder":"add shopping list item","type":"text"},domProps:{"value":(_vm.newItem)},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key)){ return null; }_vm.addItem($event)},"input":function($event){if($event.target.composing){ return; }_vm.newItem=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn-btn-default",attrs:{"type":"button"},on:{"click":_vm.addItem}},[_vm._v("Add!")])])])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-30d17408", __vue__options__)
  } else {
    hotAPI.reload("data-v-30d17408", __vue__options__)
  }
})()}